
import { takeLatest, all } from 'redux-saga/effects'
import MockApi from '../services/mock_api'
import { create } from '../services/api'
import DebugConfig from '../config/index'

/* ------------- Types ------------- */

import { HomeScreenTypes } from '../redux/home/home_redux'
import { MineScreenTypes } from '../redux/mine/mine_redux'

/* ------------- Sagas ------------- */

import { changeHomeTitle } from './home/home_saga'
import { changeMineTitle } from './mine/mine_saga'

/* ------------- API ------------- */

const api = DebugConfig.useFixtures ? MockApi : create()

/* ------------- Connect Types To Sagas ------------- */

export function * homeSagas () {
  yield all([
    takeLatest(HomeScreenTypes.CHANGE_TITLE, changeHomeTitle, api)
  ])
}

export function * mineSagas () {
  yield all([
    takeLatest(MineScreenTypes.CHANGE_TITLE, changeMineTitle, api)
  ])
}
