import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  changeTitle: ['title'],
  changeTitleSuccess: ['title']
}, {prefix: 'MINE_SCREEN_'})

export const MineScreenTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  title: '我的'
})

/* ------------- Reducers ------------- */

export const changeTitleSuccess = (state, { title }) => {
  return state.merge({
    title: title
  })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_TITLE_SUCCESS]: changeTitleSuccess
})
