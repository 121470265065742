/**
 * 处理模拟请求的配置
 */

import Mock from 'mockjs'
import rules from './rules'

function mock(arr) {
  arr.forEach(item => {
    let type
    // https://github.com/nuysoft/Mock/issues/261
    // mockjs的rtype参数只能是小写，所以在此进行了转化
    if (item.type) {
      type = item.type.toLowerCase()
    } else {
      type = 'post'
    }
    Mock.mock(`/api/${item.url}`, type, item.tpl)
  })
}

export default function() {
  mock(rules)
}
