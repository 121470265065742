
import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'

import rootReduce from '../redux/index'
import {
  homeSagas,
  mineSagas
} from '../sagas/index'

const sagaMiddleware = createSagaMiddleware()

export default createStore(
  rootReduce,
  applyMiddleware(sagaMiddleware)
)

sagaMiddleware.run(homeSagas)
sagaMiddleware.run(mineSagas)
