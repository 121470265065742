
import apisauce from 'apisauce'
import Mock from '../mock'
import DebugConfig from '../config/index'

if (DebugConfig.useMock) {
  // 开启mock逻辑
  Mock()
}

const BASE_URL = '/api'
const TIME_OUT = 60000 * 10

export const create = function(
  baseURL = BASE_URL,
  timeout = TIME_OUT,
  headers = { 'Cache-Control': 'no-cache' }
) {
  const api = apisauce.create({
    baseURL,
    timeout,
    headers: headers
  })

  return api
}
